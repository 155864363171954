import cookie from '../plugins/jquery.cookie.js';
import Headroom from '../plugins/headroom.js';

jQuery(function($) {
  // #! Redirect
  if (window.location.href.indexOf("/#!") > -1) {
    window.location.href = window.location.href.replace("/#!", "");;
  }

  // Filter Toggles
  $('.the-filter .active').click(function(){
    $('.the-filter').toggleClass('open');
  });

  // Accordion
  $('.accordion-title').click(function(){
    $(this).parents('.accordion').toggleClass('open');
  });

  // Hero animation
  function shuffle(array) {
    var m = array.length, t, i;
    while (m) {
      i = Math.floor(Math.random() * m--);
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }
    return array;
  }
  $('.global-hero').addClass('reveal');
  setInterval(function(){
    shuffle($(".global-hero .shapes-wrapper svg path:not(.shown)")).slice(0, 1).addClass('shown');
  }, 15);
  

  // Donation Form
  $('.gfield.prices input').on('change', function(){
    $('.gfield.prices label').each(function(){
      var $this = $(this);
      if ($this.siblings('input:checked').length) {
        $this.addClass('checked');
        $('.gfield.number input, .gfield.gfield_total input').val(parseInt($this.siblings('input:checked').val()).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        }));
      } else {
        $this.removeClass('checked');
      }
    });
    // console.log('1');
    // console.log($('.gfield.number input').val());
    // console.log($('.gfield.gfield_total input').val());
  });
  $('.gfield.number input').on('change', function(){
    var $this = $(this);
    $('.gfield.gfield_total input').val($this.val());
    $('.gfield.prices input').each(function() {
      var $this = $(this);
      $this.prop('checked', false);
    });
    $('.gfield.prices label').each(function() {
      var $this = $(this);
      $this.removeClass('checked');
    });
    // console.log('2');
    // console.log($('.gfield.number input').val());
    // console.log($('.gfield.gfield_total input').val());
  });
   $('.gfield.recurring input').on('change', function(){
    var $this = $(this);
    $('.gfield.recurring input:checked').each(function() {
      $('.gfield.recurring label').removeClass('checked');
      var $this = $(this);
      $this.next('label').addClass('checked');
      $('#donate-submit').val('Give ' + $('.gfield.recurring label.checked').text());
    });
  });
  // if ($('.gfield.gfield_total input').length) {
  //   setTimeout(function(){$('.gfield.gfield_total input').val($('.gfield.number input').val())},100);
  // }
  setInterval(function(){console.log($('.gfield.gfield_total input').val())}, 500);

  // Sticky Header
  var myElement = document.querySelector(".global-header");
  var headroom  = new Headroom(myElement, {
    "offset": 150,
    "tolerance": 0
  });
  headroom.init();
  
  // Cookied Popup
  var $delay = 0;
  if ($('.cookied-popup').length) {
    $delay = $('.cookied-popup').data('delay');
    setTimeout(function(){
      $('.cookied-popup').addClass('visible');
    }, $delay);
  }
  $('.close-cookie-popup').on('click', function(){
    var $cookieid = $('.cookied-popup').attr('id')
    $.cookie('popup', $cookieid, { path: '/' });
    $('.cookied-popup').fadeOut();
  });

  // Search
  $('.search-toggle').click(function(e){
    e.preventDefault();
    $('#search-popup').addClass('active');
    $('#search-input').focus();
  });
  $('.close-search').click(function(){
    $('#search-popup').removeClass('active');
  });

  // Anchor
  $('a:not(.endnote):not(.endnote-toggle)').on('click', function(e){
    var $href = $(this).attr('href'),
        $anchor = $href;
    if ($anchor.indexOf('#') > -1) {
      if (!$anchor.match('^#')) {
        $anchor = $anchor.substr($anchor.indexOf('#'));
      }
      if ($($anchor).length > 0) {
        e.preventDefault();
        $('html, body').stop().animate({
          scrollTop: $($anchor).offset().top
        });
      } else {
        window.location.href = $href;
      }
    }
  });

  // Endnote
  $('.endnotes-wrapper .endnote-toggle').on('click', function(){
    $('.endnotes-wrapper').toggleClass('open');
  });
  $(window).resize(function(){
    $('.endnote-popup').remove();
  });
  $('a.endnote').on('click', function(e){
    e.preventDefault();
    $('.endnote-popup').remove();
    if ($($(this).attr('href')).length) {
      $('body').append('<div class="endnote-popup" style="left: ' + $(this).offset().left  + 'px; top: ' + ($(this).offset().top + 40) + 'px;">' + $($(this).attr('href')).html() + '<svg class="close-icon" width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg"><line y1="-1" x2="20.6968" y2="-1" transform="matrix(0.724751 0.689011 -0.724751 0.689011 1 2)" stroke="#959494" stroke-width="2"/><line y1="-1" x2="20.6968" y2="-1" transform="matrix(0.724751 -0.689011 0.724751 0.689011 2.33008 16.2603)" stroke="#959494" stroke-width="2"/></svg></div>');
    }
  });
  $('body').on('click', '.endnote-popup .close-icon', function(){
    $(this).parent('.endnote-popup').remove();
  });

  // Video popups
  $('a:not(.social-link)').on('click', function(e){
    var $target = $(this).attr('href');
    if ($target.toLowerCase().indexOf("vimeo") >= 0) {
      e.preventDefault();
      var $n = $target.lastIndexOf('/');
      var $vid = $target.substring($n + 1);
      $('.popup-content').html('<iframe src="https://player.vimeo.com/video/' + $vid + '/?autoplay=1&api=1" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
      $('.video-popup-wrapper').addClass('active');
      $('body, html').addClass('body-noscroll');
    }
    if ($target.toLowerCase().indexOf("youtu") >= 0) {
      e.preventDefault();
      var $y = $target.lastIndexOf('=');
      var $yid = $target.substring($y + 1);
      $('.popup-content').html('<iframe src="https://www.youtube.com/embed/' + $yid + '/?autoplay=1" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
      $('.video-popup-wrapper').addClass('active');
      $('body, html').addClass('body-noscroll');
    }
  });
  $('.close-popup').on('click', function(){
    $('.video-popup-wrapper').removeClass('active');
    $('.popup-content').html('');
    $('body, html').removeClass('body-noscroll');
  });

  // Mobile Nav
  $('#mobile-nav-toggle').on('click', function(){
    var $navheight = $('#mobile-navigation').height() + 170;
    $('body, html').toggleClass('nav-locked');
    $('#mobile-navigation-wrapper').toggleClass('active');
    $(this).toggleClass('active');
  });
  $('.mobile-navigation-wrapper .menu-item-has-children').click(function(){
    $(this).toggleClass('open');
  });

  // Resize for Match-Height and Slick-Slider glitch fix
  scroll_actions();
  $(window).bind('load', function(){
    $(window).trigger('resize');
    setTimeout(function(){ $(window).trigger('resize'); }, 500);
    scroll_actions();
  });
  $(document).ready(scroll_actions());
  $(window).scroll(scroll_actions());

  function scroll_actions() {
    $('.loading').each(function(){
      $(this).addClass('loaded');
    });
    $('.animate').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        $this.addClass('visible');
      }
    });
    $('.animate-1').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 100);
      }
    });
    $('.animate-2').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 200);
      }
    });
    $('.animate-3').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 300);
      }
    });
    $('.animate-4').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 400);
      }
    });
    $('.animate-5').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 500);
      }
    });
    $('.animate-6').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 600);
      }
    });
    $('.animate-7').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 700);
      }
    });
    $('.animate-8').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 800);
      }
    });
    $('.animate-9').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 900);
      }
    });
    $('.animate-10').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1000);
      }
    });
    $('.animate-11').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1100);
      }
    });
    $('.animate-12').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1200);
      }
    });
    $('.animate-13').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1300);
      }
    });
    $('.animate-14').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1400);
      }
    });
    $('.animate-15').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1500);
      }
    });
    $('.animate-16').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1600);
      }
    });
    $('.animate-17').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1700);
      }
    });
    $('.animate-18').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1800);
      }
    });
    $('.animate-19').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1900);
      }
    });
    $('.animate-20').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 2000);
      }
    });
    $('.animate-21').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 2100);
      }
    });
  }

});